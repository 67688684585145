<template>
    <div style="background-color: white; padding-bottom: 100%;">
        <div id="iframeMain" class="Main" @change="ChangeSize()" @click="ChangeSize()" :style="minWidth" >
            <div class="OrderHeader" v-if="reviewOrders.getOrderByKeyLoading === false && iframeOrder.SystemDisplayOrderID !== null && Status.getIframeSettingsURLLoading === false && iframeSettings.Name != 'null'">
                <!-- <p> Name: {{iframeOrder.Name}}</p> -->
                <p id="thankYou" v-if="appState !== 'OptOutWarn' && appState !== 'OptOut'">Thank you for your purchase. In order to complete your order we need some additional information from you.</p>
                <p id="importantMsg" v-if="appState !== 'OptOutWarn' && appState !== 'OptOut'">IMPORTANT: We cannot process your order until this information is provided.  If we do not receive the required information within 3 days, your order will be cancelled.</p>
                <div id="OrderInfo" v-if="appState !== 'OptOutWarn' && appState !== 'OptOut'">
                    <p id="orderNum"><span id="orderNumLabel">Order #:</span> <span id="orderNumVal">{{iframeOrder.SystemDisplayOrderID}}</span></p>
                    <div>
                        <p><span id="productLabel">Products:</span></p>
                        <ul id="productList">
                            <li class="productVal" v-for="product in iframeOrder.Products" :key="product.ID">{{ product.Name }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="OrderHeader" v-if="reviewOrders.getOrderByKeyLoading === false && iframeOrder.SystemDisplayOrderID === null && Status.getIframeSettingsURLLoading === false && iframeSettings.Name != 'null'">
                <p id="badLinkMsg">This link either expired or does not exist, please contact support.</p>
            </div>
            <div class="OrderHeader" v-if="iframeSettings.Name === 'null' && Status.getIframeSettingsURLLoading === false">
                <p>This domain is not authorized. {{ ParentURL }}</p>
            </div>
            <!--REWORK-->
            <div v-if="reviewOrders.getOrderByKeyLoading === false && iframeOrder.SystemDisplayOrderID !== null && Status.getIframeSettingsURLLoading === false && iframeSettings.Name != 'null'">
                <div v-if="appState !== 'OptOutWarn' && appState !== 'OptOut'">
                    <div id="chosenContainer"><!--list of chosen exems-->
                        <div class="chosen" v-for="(item, index) in appState === 'FillExem' ? chosen.slice(0, chosen.length-1) : chosen" :key="item.id">
                            <div id="chosenIconAndName">
                                <i v-if="item.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-18"></i>
                                <i v-if="item.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-18"></i>
                                <i v-if="item.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-18"></i>
                                <i v-if="item.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-18"></i>
                                {{item.name}}
                            </div> 
                            <button class="exemptionChangeButton" @click="Back(index)" style="text-decoration: underline;">change</button>
                        </div>
                    </div>
                    <div id="ChooseTrade" v-if="appState === 'ChooseTrade'">
                        <div id="chooseTradeHeader">
                            <h3 id="startMessage" style="border: 1px solid #aaa; width: 95%; margin-left:auto; margin-right:auto;">We are unable to process your order until you submit the required information.</h3>
                            <h4 id="pleaseSelectMessage">Please select one option below that applies to you.</h4>
                            <h3 id="IAmA">I am a...</h3>
                        </div>
                        <div id="chooseTradeBody">
                            <template v-for="ttype of TTypes">
                                <div v-if="IsNotEmpty(ttype.ID) && reviewOrders.getOrderRuleSetLoading === false && reviewOrders.getOrderProductsByKeyLoading === false && iframeOrder.SystemDisplayOrderID !== null && Status.getIframeSettingsURLLoading === false && iframeSettings.Name != 'null'"
                                    :key="ttype.ID" class="optionCard" @mouseenter="Hover($event)" @mouseleave="EndHover($event)" @click="chosen.push({id: 'Trade', name: 'I am a ' + ttype.Name.toUpperCase(), TradeID: ttype.ID}); appState = 'ChooseExem'; flags = ttype.ID; UpdateDupes();">
                                    <h2 class="optionCardTradeName">{{ ttype.Name }}</h2>
                                </div>
                            </template>
                        </div>
                    </div>
                    <!--display options-->
                    <div id="ChooseExem" v-if="appState === 'ChooseExem'">
                        <h3 id="chooseExemTitle" v-show="ExemCount !== 0">
                            <div class="chooseExemTitleHeader">Please select one of the items you have from the list below.</div> 
                            <div class="chooseExemTitleBody">Depending on your selection, we'll guide you through providing the appropriate combination of required items.</div>
                        </h3>
                        <div>
                            <!--BASIC ALL-->
                            <template v-for="exem in iframeOrder.BasicAndList[flags]"><!--DUPECHANGE\/\/-->
                                <div class="optionCard" @mouseenter="Hover($event)" @mouseleave="EndHover($event)" v-if="(!chosen.some(e =>{return JSON.stringify(exem) === JSON.stringify(e)}))" :key="exem.id" @click="chosen.push(exem); appState = 'FillExem'; formExemType = 'and'; SelectDupes(exem.id); ShowDetails(exem.id, exem.exemptionDataType)">
                                    <div class="optionName">{{ exem.name }}</div>
                                    <div class="PrdDiv">
                                        Required For:
                                        <ul class="optionProductList">
                                            <li class="optionProduct" v-for="prd in exem.products" :key="prd">{{ iframeOrder.Products[prd].Name }}</li>
                                        </ul>
                                    </div>
                                    <div class="optionStatus"> 
                                        <div class="StatusLabel">{{ exem.status }}</div>
                                        <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-24"></i>
                                        <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-24"></i>
                                        <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-24"></i>
                                        <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-24"></i>
                                    </div>
                                </div>
                            </template>
                            <!--BASIC ANY-->
                            <template v-for="AnySet in iframeOrder.BasicOrList[flags]">
                                <template v-if="AnySet.chosen === ''">
                                    <template v-for="exem in AnySet.exemptions"><!--DUPECHANGE\/\/-->
                                        <div class="optionCard" @mouseenter="Hover($event)" v-if="NotDuped(exem.id, AnySet.id)" @mouseleave="EndHover($event)" :key="exem.id" @click="/*AnySet.chosen = exem.id;*/ chosen.push({...exem, AnySet: AnySet.id}); appState = 'FillExem'; formExemType = 'sor'; SelectDupes(exem.id, AnySet.id); ShowDetails(exem.id, exem.exemptionDataType)" >
                                            <div class="optionName">{{ exem.name }}</div>
                                            <div class="PrdDiv">
                                                Required For:
                                                <ul class="optionProductList">
                                                    <li class="optionProduct" v-for="prd in exem.products" :key="prd">{{ iframeOrder.Products[prd].Name }}</li>
                                                </ul>
                                            </div>
                                            <div class="optionStatus">
                                                <div class="StatusLabel">{{ exem.status }}</div>
                                                <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-24"></i>
                                                <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-24"></i>
                                                <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-24"></i>
                                                <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-24"></i>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </template>
                            <!--COMPLEX-->
                            <template v-for="Rule in iframeOrder.ComplexOrList[flags]">
                                <template v-for="set in Rule.exemptionSets">
                                    <template v-if="Rule.chosen === '' || Rule.chosen === set.id">
                                        <!--ALL-->
                                        <template v-for="exem in set.andExemptions"><!--DUPECHANGE\/\/-->
                                            <div class="optionCard" @mouseenter="Hover($event)" @mouseleave="EndHover($event)" :key="exem.id + '__' + Rule.id" v-if="!chosen.some(e =>{return JSON.stringify({...exem, OrSet: Rule.id}) === JSON.stringify(e)}) && NotDuped(exem.id, null, Rule.id)" @click="Rule.chosen = set.id; chosen.push({...exem, OrSet: Rule.id}); appState = 'FillExem'; formExemType = 'cor'; SelectDupes(exem.id, null, Rule.id); ShowDetails(exem.id, exem.exemptionDataType)">
                                                <div class="optionName">{{ exem.name }}</div>
                                                <div class="PrdDiv">
                                                    Required For:
                                                    <ul class="optionProductList">
                                                        <li class="optionProduct" v-for="prd in exem.products" :key="prd">{{ iframeOrder.Products[prd].Name }}</li>
                                                    </ul>
                                                </div>
                                                <div class="optionStatus">
                                                    <div class="StatusLabel">{{ exem.status }}</div>
                                                    <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-24"></i>
                                                    <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-24"></i>
                                                    <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-24"></i>
                                                    <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-24"></i>
                                                </div>
                                            </div>
                                        </template>
                                        <!--ANY-->
                                        <template v-if="Object.keys(set.orExemptions).length > 0 && set.chosen === ''"><!--DUPECHANGE\/\/-->
                                            <template v-for="exem in set.orExemptions">
                                                <div class="optionCard" @mouseenter="Hover($event)" @mouseleave="EndHover($event)" v-if="NotDuped(exem.id, set.id, Rule.id)" :key="exem.id + '_' + set.id" @click="Rule.chosen = set.id;set.chosen = exem.id; chosen.push({...exem, OrSet: Rule.id, Set: set.id}); appState = 'FillExem'; SelectDupes(exem.id, set.id, Rule.id); ShowDetails(exem.id, exem.exemptionDataType)">
                                                    <div class="optionName">{{ exem.name }}</div>
                                                    <div class="PrdDiv">
                                                        Required For:
                                                        <ul class="optionProductList">
                                                            <li class="optionProduct" v-for="prd in exem.products" :key="prd">{{ iframeOrder.Products[prd].Name }}</li>
                                                        </ul>
                                                    </div>
                                                    <div class="optionStatus">
                                                        <div class="StatusLabel">{{ exem.status }}</div>
                                                        <i v-if="exem.status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-24"></i>
                                                        <i v-if="exem.status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-24"></i>
                                                        <i v-if="exem.status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-24"></i>
                                                        <i v-if="exem.status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-24"></i>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </div>
                    <!--Thank You Message-->
                    <div id="thankYouMessage" v-if="appState === 'ChooseExem' && (IsEmpty('ChooseExem') || ExemCount === 0)">
                            <h3>Thank you, please wait for us to review your information.</h3>
                    </div>
                    <div id="FillExem" v-if="appState === 'FillExem'">
                        <h3></h3>
                        <div id="FormCard">
                            <div id="FormInfo">
                                <h4 style="margin-top: 0;" id="formTitle">
                                    <i v-if="chosen[chosen.length-1].status === 'Accepted'" title="Accepted" class="mdi mdi-checkbox-marked-circle text-success font-20"></i>
                                    <i v-if="chosen[chosen.length-1].status === 'Denied'" title="Denied" class="mdi mdi-close-circle text-danger font-20"></i>
                                    <i v-if="chosen[chosen.length-1].status === 'Received'" title="Received" class="mdi mdi-clock text-warning font-20"></i>
                                    <i v-if="chosen[chosen.length-1].status === 'Unsubmitted'" title="Unsubmitted" class="mdi mdi-minus-circle-outline font-20"></i>
                                    {{ chosen[chosen.length-1].name }}
                                </h4>
                                <p id="formDescription">{{ chosen[chosen.length-1].description }}</p>
                                <p class="showHideProducts" v-if="!showPrd" style="margin-bottom: 8px;" @click="showPrd = true">Show Requiring Products <i class="uil-angle-down font-18"></i></p>
                                <p class="showHideProducts" v-if="showPrd" @click="showPrd = false">Hide Requiring Products <i class="uil-angle-up font-18"></i></p>
                                <ul id="formProductsList" v-if="showPrd">
                                    <li class="formProductsListItem" v-for="prd in chosen[chosen.length-1].products" :key="prd">{{ iframeOrder.Products[prd].Name }}</li>
                                </ul>
                            </div>
                            <form @submit="FormSubmit($event, chosen[chosen.length-1].id, flags ,chosen[chosen.length-1].exemptionDataType, formExemType, chosen[chosen.length-1].exemptionTypeID, chosen[chosen.length-1].exemptionDataType, chosen[chosen.length-1].exemptionDataTypeDetailID)" id="InputForm" v-html="formHTML"></form>
                            <br id="formTypeBreak" v-if="chosen[chosen.length-1].exemptionDataType === 'Form'">
                            <!-- Update button to text to "Submit" if ExemptionDataType is a Form and "Upload" if it is anything else (file, etc...) -->
                            <button id="formUploadButton" v-if="chosen[chosen.length-1].exemptionDataType === 'Form'" @click="TrySubmit()">Submit</button>
                            <button id="formUploadButton" v-if="chosen[chosen.length-1].exemptionDataType !== 'Form'" @click="TrySubmit()">Upload</button>
                            <!-- End update button to text to "Submit" if ExemptionDataType is a Form and "Upload" if it is anything else (file, etc...) -->
                            <button id="formNextButton" @click="appState = 'ChooseExem'; showPrd = false" :disabled="chosen[chosen.length-1].status === 'Unsubmitted' || chosen[chosen.length-1].status === 'Denied'">Next</button>
                            <div id="formStatusContainer" style="display: inline;">
                                <i v-if="submitStatus === 'Loading'" class="mdi mdi-spin mdi-loading font-16">Uploading</i>
                                <!-- Update response status text based on whether it is a form or other type of ExemptionDataType -->
                                <i v-if="submitStatus === 'Success' && chosen[chosen.length-1].exemptionDataType === 'Form'" class="mdi mdi-check font-16 valid-feedback" style="display: inline">Form data received successfully!</i>
                                <i v-if="submitStatus === 'Success' && chosen[chosen.length-1].exemptionDataType !== 'Form'" class="mdi mdi-check font-16 valid-feedback" style="display: inline">Success</i>
                                <!-- End update response status text based on whether it is a form or other type of ExemptionDataType -->
                                <i v-if="submitStatus !== 'Loading' && submitStatus !== 'Success' && submitStatus !== 'None' " class="mdi mdi-close font-16 invalid-feedback" style="display: inline">{{ submitStatus }}</i>
                            </div>
                            <div v-if="integrations[chosen[chosen.length-1].id] !== undefined && integrations[chosen[chosen.length-1].id].find((e) => e.integrationID === 10) !== undefined">
                                <span style="margin-top: .5em;display:inline-block"> - OR - </span>
                                <button id="IDmeBtn" @click="IDmeVerifyBtn()"></button>
                                <a id="IDmeA" href="https://www.id.me/about" target="_new">What is ID.me?</a>
                            </div>
                            
                            <button id="BackButton" @click="Back(chosen.length - 1);" style="text-decoration: underline;">change</button>
                        </div>
                    </div>
                    <div>
                        <button class="OptOut" id="OptOut" @click="appState = 'OptOutWarn'" v-if="appState !== 'ChooseTrade' && ExemCount !== 0">I cannot provide any information</button>
                    </div>
                </div>
                <div v-if="appState === 'OptOutWarn'" class="OptOutMessage">
                    <h3>Without providing the necessary information, we are unable to ship certain products in your order. If you choose to continue, the affected items will be removed from your order.</h3>
                    <button @click="OptOutCancel()" class="OptOut" id="OptOutCancel">Cancel</button>
                    <button id="OptOutConfirm" class="OptOut" @click="OptOutProtocol()">Continue</button>
                </div>
                <div v-if="appState === 'OptOut'" class="OptOutMessage">
                    <h3>Since you've chosen not to provide the required information, we will remove the affected products from your order. Thank you for your understanding.</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/zoid@9.0.86/dist/zoid.min.js" integrity="sha256-cH6yrsJ1Ymb0fQx62lQsSE//0WD0DR8v2uuvqj74oSA=" crossorigin="anonymous"></script>

<script>
import zoid from 'zoid';
import { mapActions, mapState } from "vuex";
import { nextTick } from 'vue';
import { credentialReviewService } from '../../_services';
import iframeOldVue from './iframeOld.vue';

const zoidComponentInit = zoid.create({
        tag: "echeckpointIframe",
        url: "https://portal.echeckpoint.com/iframe?enablejsapi=1"
})


export default {
    data() {
    return {
        minWidth: "",
        HashKey: 0,
        SubscriberSettings:
        {
            backColor: "#fff3ff",
        },
        ParentURL: "null",
        FlowID: null,
        FrameWidth: "320px",
        testing: [],
        flags: '',
        TTypes: [
            {ID: "1", Type: "Consumer", Name: "Consumer"},
            {ID: "2", Type: "Business", Name: "Business"},
            {ID: "3", Type: "Government", Name: "Gov't Rep"},
        ],
        //added for rework
        appState: 'ChooseTrade',
        chosen: [],
        ExemCount: null,
        ExemCountChanged: false,
        formHTML: '',
        formExemType: '',
        OptOut: false,
        OptOutWarn: false,
        showPrd: false,
        currentlyChosen: null,
        IDmeOn: false,
        
    };
  },
  computed: {
    ...mapState('credentialReview', ["reviewOrders", "iframeOrder", "FormTemplates", "FormTemplates", "submitStatus", "integrations"]),
    ...mapState('settings', ["iframeSettings", "Status"]),
  },
  created() {
    this.Initialize();
    this.getSettingsByURL(this.ParentURL);
    this.getFormTemplate(this.HashKey);
    this.getIntegrations({ID: this.HashKey, URL: this.ParentURL});
  },
  mounted() {
    this.OrderByKey();
    this.GetOrderRuleSet();
    this.$nextTick(() => {
        this.LoadWait(100);

    });
    this.addStyling();
  },
  
  methods: {
    ...mapActions("settings", ["getIframeSettingsByURL"]),
    ...mapActions("credentialReview", ["getCredentialReviewOrderByKey", "getOrderRuleSet", "getCredentialReviewOrderProductsByKey", "uploadData", "getFormTemplate", "submitForm", "finalizeSubmission", "IDmeVerify", "getIntegrations"]),
    Initialize()
    {
        //get base url
        const tempString = window.xprops.URL.split('?');
        this.ParentURL = tempString[0];

        //getting params from the url
        const search = new URLSearchParams(window.xprops.Params)
        this.HashKey = search.get('key');
        this.FlowID = search.get('flow');
        this.FrameWidth = window.xprops.FrameWidth;

    },
    ChangeSize()
    {
        window.xprops.ChangeSize("100%", document.getElementById("iframeMain").scrollHeight + 'px');
    },
    getSettingsByURL(url)
    {
        const temp = {url: url, Flow: Number(this.FlowID)}
        this.getIframeSettingsByURL(temp);
    },
    OrderByKey()
    {
        this.getCredentialReviewOrderByKey(this.HashKey);
        this.getCredentialReviewOrderProductsByKey(this.HashKey);
    },
    GetOrderRuleSet()
    {
        this.getOrderRuleSet(this.HashKey)
    },
    IsNotEmpty(ttype)
    {
        const throwaway = this.iframeOrder;
        if(this.reviewOrders.getOrderRuleSetLoading === false)
        {
            if(Object.keys(this.iframeOrder.BasicAndList || {}).length !== 0 && Object.keys(this.iframeOrder.BasicOrList).length !== 0 && Object.keys(this.iframeOrder.ComplexOrList).length !== 0)
            {
                if(this.iframeOrder.BasicAndList[ttype].length === 0 
                && Object.keys(this.iframeOrder.BasicOrList[ttype]).length === 0 
                && Object.keys(this.iframeOrder.ComplexOrList[ttype]).length === 0)
                {
                    return false;
                }
                return true;
            }
        }
        return false;
    },
    LoadWait(interval)
    {   
        setTimeout(() => {
            if(this.reviewOrders.getOrderRuleSetLoading === true || this.reviewOrders.getOrderByKeyLoading === true || this.reviewOrders.getOrderProductsByKeyLoading === true)
            {
                return this.LoadWait(interval);
            }
            else
            {
                setTimeout(() => {this.minWidth = 'min-width: 1px;';this.ChangeSize(); this.$nextTick(()=>{setTimeout(() => {this.ChangeSize();}, 50)})}, 50);
                return;
            }
        }, interval)
    },
    ShowDetails(id, type)
    {
        //let newForm = document.getElementById("InputForm");
        this.$store.commit("credentialReview/ResetSubmitStatus");
        //remove continue button before it gets removes
        this.formHTML = '';
        if(type === "File")
        {
            this.formHTML = `<input type="file" required accept=".jpg, .jpeg, .png, .bmp, .pdf, .docx">`
            return;
        }

        const newForm = document.createElement("form");
        for(const field of this.FormTemplates[id].fields)
        {
            if(field.ElementType.toLowerCase() === "message")
            {
                let newMessage = document.createElement("span");
                newMessage.innerHTML = field.Message;
                newForm.append(newMessage)
            }
            else
            {
                let newLabel = document.createElement("label");
                newLabel.innerHTML = field.Label + ":<nbsp/>";
                newLabel.setAttribute("for", field.Label.replace(/\s/g, ''));
                newLabel.setAttribute("style", "width: 120px;");
                newForm.append(newLabel);

                let newField = document.createElement("input");
                newField.setAttribute("id", field.Label.replace(/\s/g, ''));
                newField.setAttribute("name", field.Label);
                newField.setAttribute("type", field.ElementType);
                newField.setAttribute("value", field.DefaultValue);
                if(field.Required)
                    newField.setAttribute("required", true);
                newForm.append(newField);
            }
            
            newForm.append(document.createElement("br"));
        }
        //let btnTest = document.createElement("input");
        //btnTest.setAttribute("type", "submit");
        //btnTest.setAttribute("style", this.iframeSettings.Style['Button']);
        //newForm.append(btnTest);
        this.formHTML = newForm.innerHTML;
    },
    FormSubmit(event, exemId, trade, inputType, listType, exemptionTypeID, EDType, EDDetail)
    {
        event.preventDefault();
        event.target.checkValidity();
        if(inputType === "File")
        {
            const HashKey = this.HashKey;
            let file = event.target.firstElementChild.files[0];
            let formData = new FormData();
            formData.append("formFile", file);
            formData.append("orderID", this.iframeOrder.OrderID);
            formData.append("exemption", exemId);
            formData.append("statusID", 2);
            formData.append("CustomerID", this.iframeOrder.CustomerID);
            //const check = this.uploadData({formData, trade, listType, exemId, HashKey});
            this.uploadData({formData, trade, listType, exemId, HashKey})

            this.AwaitStatus(0);
            
        }
        else
        {
            const formData = new FormData(event.target);
            let dataArr = [...formData];

            //checks for checkboxes
            for(const field of this.$store.state.credentialReview.FormTemplates[exemId].fields)
            {
                if(field.ElementType === "checkbox")
                {
                    let inSet = false;
                    for(const pair of dataArr)
                    {
                        if(field.Label === pair[0])
                        {
                            inSet = true;
                        }
                    }
                    if(!inSet)
                    {
                        dataArr = [...dataArr, [field.Label , ""]]
                    }
                }
            }
            
            const toSend = {formData: dataArr, OrderID: this.iframeOrder.OrderID, exemption: exemId, formTemplateID: this.FormTemplates[exemId].id, statusID: 2, exemptionTypeID: exemptionTypeID, displayOrderID: this.iframeOrder.SystemDisplayOrderID, exemptionDataType: EDType, exemptionDataTypeDetailID:EDDetail, subscriberID: this.iframeOrder.SubscriberID };
            this.submitForm({toSend, trade, listType, exemId});
            this.AwaitStatus(0);
        }
    },
    AwaitStatus(i)
    {
        setTimeout(() => {
            if(this.reviewOrders.uploadDataLoading === true || this.reviewOrders.IDmeVerifyLoading === true)
            {
                this.AwaitStatus(++i);
            }
            else if(i > 500)
                return;
            else
            {
                //console.log("status done");
                this.chosen[this.chosen.length -1].status = this.$store.state.credentialReview.currentExemStatus;

                const exemID = this.chosen[this.chosen.length -1].id;
                const theRule = this.iframeOrder.ComplexOrList[this.chosen[0].TradeID][this.chosen[this.chosen.length -1].OrSet];
                if(theRule)
                {
                    for(const set of Object.entries(theRule.exemptionSets))
                    {
                        if(set[1].orExemptions[exemID])
                            set[1].orExemptions[exemID].status = this.$store.state.credentialReview.currentExemStatus;
                        if(set[1].andExemptions[exemID])
                            set[1].andExemptions[exemID].status = this.$store.state.credentialReview.currentExemStatus;
                    }
                }

            }
        }, 200);
    },
    //REWORK
    IsEmpty(name)
    {
        this.$nextTick(() => {
            const oldCount = this.ExemCount;

            this.ExemCount = document.getElementById(name).firstElementChild.nextElementSibling.childElementCount;
            this.FixBorkedProductDisplay();

            if(oldCount !== this.ExemCount)
                this.ExemCountChanged = true;
            else
                this.ExemCountChanged = false;

            if(this.ExemCountChanged && this.ExemCount === 0)
            {
                var path = "";
                for(const chosen of this.chosen.slice(1))
                {
                    path += chosen.id + ",";
                }
                path = path.substring(0, path.length - 1);
                this.finalizeSubmission({OrderID: this.iframeOrder.ID, Path: path, TradeType: Number(this.chosen[0].TradeID)});
            }
        });
    },
    Back(index)
    {
        this.showPrd = false;
        const AnySets = [];
        const OrSets = [];
        const exems = []
        const length = this.chosen.length

        
        for(let i = 0; i < length - index; ++i)
        {
            //const temp = this.chosen[index]
            

            const out = this.chosen.pop();

            if(out.AnySet)
            {
                AnySets.push(out.AnySet);
            }
            else if(out.Set)
            {
                OrSets.push({id: out.OrSet, set: out.Set})
            }
            else if(out.OrSet)
            {
                OrSets.push({id: out.OrSet});
            }

            if(out.id !== "Trade")
                exems.push({id: out.id, Set: out.Set, OrSet: out.OrSet})
        }

        for(const anySet of AnySets)
        {
            this.iframeOrder.BasicOrList[this.flags][anySet].chosen = "";
        }
        for(const orSet of OrSets)
        {
            if(orSet.set)
                this.iframeOrder.ComplexOrList[this.flags][orSet.id].exemptionSets[orSet.set].chosen = "";

            if(this.chosen.find((el) => el.OrSet === orSet.id) === undefined)
                this.iframeOrder.ComplexOrList[this.flags][orSet.id].chosen = "";

        }
        for(const out of exems)
        {
            this.BackDupes(out.id, out.Set, out.OrSet)
        }
        this.BackChoices()

        if(this.chosen.length > 0)
        {
            this.appState = "ChooseExem";
        }
        else
        {
            this.flags = "";
            this.appState = "ChooseTrade";
        }
    },
    Hover(event)
    {
        event.target.style =  this.iframeSettings.Style['OptionCard'] + this.iframeSettings.Style['OptionCardHover'];
    },
    EndHover(event)
    {
        event.target.style =  this.iframeSettings.Style['OptionCard'];
    },
    TrySubmit()
    {
      document.getElementById("InputForm").requestSubmit();
    },
    FixBorkedProductDisplay()
    {
        const cards = document.getElementsByClassName("optionCard");
        const products = document.getElementsByClassName("PrdDiv");

        const lH = 20;//Line height in px

        for(let i = 0; i < cards.length; ++i)
        {
            
            const cH = cards[i].offsetHeight;
            const pH = products[i].offsetHeight;

            const allowedH = cH * 0.45;//fraction of div for product use
            if(pH > allowedH)
            {
                products[i].style.height = (Math.floor(allowedH / lH) * lH) + "px"
            }
        }
    },
    addStyling()
    {
        setTimeout(() => {
            if(this.$store.state.settings.Status.getIframeSettingsURLLoading === true)
            {
                return this.addStyling();
            }
            else
            {
                document.getElementsByTagName("head")[0].innerHTML += `<style id="mystyle">${this.$store.state.settings.iframeSettings.Style}</style>`;
                return;
            }
        }, 300)
    },
    IDmeVerifyBtn()
    {
        const state = this.iframeOrder.ID + "_" + this.chosen[this.chosen.length - 1].id;
        this.IDmeVerify({ID: state, URL: this.ParentURL});

        const scope = this.integrations[this.chosen[this.chosen.length-1].id].find((e) => e.integrationID === 10).scope;
        const client_id = this.integrations[this.chosen[this.chosen.length-1].id].find((e) => e.integrationID === 10).apiUsername;
        const width = 800;
        const height = 900;

        window.open("https://api.id.me/oauth/authorize?&scope=" + scope + "&state=" + state + "&client_id=" + client_id + "&redirect_uri=https://portal.echeckpoint.com/idmeRedirect&response_type=code&type=button", "_blank", "width=" + width + ",height=" + height + ",popup");
        this.AwaitStatus(0);
    },
    NotDuped(exemID, setID, RuleID)
    {
        let state = 0;
        if(RuleID)
            state = 2;
        if(setID)
            state += 1;

        //this.UpdateDupes()

        switch(state)
        {
            case 0:
                return this.iframeOrder.ExemDupeTracker[this.flags][exemID].find((e) =>  {return !e.AnyID && !e.OrID}).show
            case 1:
                return this.iframeOrder.ExemDupeTracker[this.flags][exemID].find((e) =>  {return e.AnyID === setID}).show
            case 2:
                return this.iframeOrder.ExemDupeTracker[this.flags][exemID].find((e) =>  {return !e.AnyID && e.OrID === RuleID}).show
            case 3:
                return this.iframeOrder.ExemDupeTracker[this.flags][exemID].find((e) =>  {return e.AnyID === setID && e.OrID === RuleID}).show
        }
    },
    UpdateDupes()
    {
        for(const exemID in this.iframeOrder.ExemDupeTracker[this.flags])
        {
            let trueFound = false
            for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exemID])
            {
                if(dupe.choice && !trueFound)
                {
                    dupe.show = true;
                    trueFound = true
                }
                else
                {
                    dupe.show = false;
                }
            }
        }
    },
    UpdateChoices(ExemID, setID, RuleID, Choice)
    {
        let state = 0;
        if(RuleID)
            state = 2
        if(setID)
            state += 1

        switch(state)
        {
            case 0:
                break;
            case 1:
                //find other exemptions in set make choice = false
                {
                    const others = this.iframeOrder.BasicOrList[this.flags][setID].exemptions
                    for(const exem of others)
                    {
                        this.iframeOrder.ExemDupeTracker[this.flags][exem.id].find((e)=> {return e.AnyID === setID && !RuleID}).choice = Choice;
                    }
                    break;
                }
            case 2:
                {
                    const ChosenSet = this.iframeOrder.ComplexOrList[this.flags][RuleID].exemptionSets[setID]

                    const sets = this.iframeOrder.ComplexOrList[this.flags][RuleID].exemptionSets
                    for(const set in sets)
                    {
                        if(set === setID)
                            continue;
                        //anys
                        for(const exemID in sets[set].orExemptions)
                        {
                            for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exemID])
                            {
                                if(RuleID === dupe.OrID && dupe.AnyID !== setID)
                                    dupe.choice = Choice;
                            }
                        }
                        //alls
                        for(const exemID in sets[set].andExemptions)
                        {
                            for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exemID])
                            {
                                if(RuleID === dupe.OrID && dupe.AndID !== setID)
                                    dupe.choice = Choice;
                            }
                        }
                    }
                    break;
                }
            case 3:
                {
                    const ChosenSet = this.iframeOrder.ComplexOrList[this.flags][RuleID].exemptionSets[setID]
                    for(const exemID in ChosenSet.orExemptions)
                    {
                        this.iframeOrder.ExemDupeTracker[this.flags][exemID].find((e)=> {return e.OrID === RuleID && e.AnyID === setID}).Choice = false
                    }

                    const sets = this.iframeOrder.ComplexOrList[this.flags][RuleID].exemptionSets
                    for(const set in sets)
                    {
                        if(set === setID)
                            continue;
                        //anys
                        for(const exemID in sets[set].orExemptions)
                        {
                            for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exemID])
                            {
                                if(RuleID === dupe.OrID && dupe.AnyID !== setID)
                                    dupe.choice = Choice;
                            }
                        }
                        //alls
                        for(const exemID in sets[set].andExemptions)
                        {
                            for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exemID])
                            {
                                if(RuleID === dupe.OrID && dupe.AndID !== setID)
                                    dupe.choice = Choice;
                            }
                        }
                    }
                    break;
                }
        }

    },
    SelectDupes(exemID)
    {
        for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exemID])
        {
            let state = 0;
            if(dupe.OrID)
                state = 2
            if(dupe.AnyID)
                state += 1
            
            switch(state)
            {
                case 0: //AndAll
                    break;
                case 1: //AndAny
                    if(this.iframeOrder.BasicOrList[this.flags][dupe.AnyID].chosen === "")
                    {
                        this.iframeOrder.BasicOrList[this.flags][dupe.AnyID].chosen = dupe.id;
                        this.UpdateChoices(exemID, dupe.AnyID, null, false)
                    }
                    break;
                case 2: //OrAll
                    if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === "")
                    {
                        this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen = dupe.AndID;
                        this.UpdateChoices(exemID, null, dupe.OrID, false)
                    }
                    break;
                case 3: //OrAny
                    if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === "")
                    {
                        this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen = dupe.AnyID;
                        this.UpdateChoices(exemID, dupe.AnyID, dupe.OrID, false)
                    }
                    if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].exemptionSets[dupe.AnyID].chosen === "")
                    {
                        this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].exemptionSets[dupe.AnyID].chosen = dupe.id;
                        this.UpdateChoices(exemID, dupe.AnyID, dupe.OrID, false)
                    }
                    break;
            }
        
        }

        this.UpdateDupes();
    },
    BackDupes(exemID, setID, ruleID)
    {
        for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exemID])//add exems in the same rules/sets
        {

            let state = 0;
            if(dupe.OrID)
                state = 2
            if(dupe.AnyID)
                state += 1
            
            switch(state)
            {
                case 0: //AndAll
                    break;
                case 1: //AndAny
                    if(this.iframeOrder.BasicOrList[this.flags][dupe.AnyID].chosen === exemID)
                    {
                        this.iframeOrder.BasicOrList[this.flags][dupe.AnyID].chosen = "";
                        //this.BackChoices(dupe.id, dupe.AnyID, dupe.OrID);
                    }
                    break;
                case 2: //OrAll
                    if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === dupe.AnyID && !this.chosen.find((e)=> {return e.AnySet === setID && e.OrSet === ruleID}))
                    {
                        this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen = "";
                        //this.BackChoices(dupe.id, dupe.AnyID, dupe.OrID);
                    }
                    break;
                case 3: //OrAny
                    if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].exemptionSets[dupe.AnyID].chosen === exemID)
                    {
                        this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].exemptionSets[dupe.AnyID].chosen = ""
                        //this.BackChoices(dupe.id, dupe.AnyID, dupe.OrID);
                    }
                    if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === dupe.AnyID && !this.chosen.find((e)=> {return e.OrSet === dupe.OrID}))
                    {
                        this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen = "";
                        //this.BackChoices(dupe.id, dupe.AnyID, dupe.OrID);
                    }
                    break;
                    
            }

            
        
        }
        this.UpdateDupes();
    },
    BackChoices(/*exemID, setID, ruleID*/)
    {
        for(const exem in this.iframeOrder.ExemDupeTracker[this.flags])
        {
            for(const dupe of this.iframeOrder.ExemDupeTracker[this.flags][exem])
            {
                let state = 0;
                if(dupe.OrID)
                    state = 2;
                if(dupe.AnyID)
                    state += 1;

                switch(state)
                {
                    case 0:
                        break;
                    case 1:
                        if(this.iframeOrder.BasicOrList[this.flags][dupe.AnyID].chosen === "")
                            this.iframeOrder.ExemDupeTracker[this.flags][dupe.id].find((e)=> {return e.AnyID === dupe.AnyID && !e.OrID}).choice = true;
                        break;
                    case 2:
                        if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === "" || this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === dupe.AnyID)
                        {
                            this.iframeOrder.ExemDupeTracker[this.flags][dupe.id].find((e)=> {return e.OrID === dupe.OrID}).choice = true;
                        }
                        break;
                    case 3:
                        if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === "" || this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].chosen === dupe.AnyID)
                        {
                            if(this.iframeOrder.ComplexOrList[this.flags][dupe.OrID].exemptionSets[dupe.AnyID].chosen === "")
                            {
                                this.iframeOrder.ExemDupeTracker[this.flags][dupe.id].find((e)=> {return e.OrID === dupe.OrID && e.AnyID === dupe.AnyID}).choice = true;
                            }
                        }
                        break;
                }
            }
        }
        this.UpdateDupes();
    },
    OptOutProtocol()
    {
        console.log("It that heralds the end....")
        this.appState = "OptOut";
        this.finalizeSubmission({OrderID: this.iframeOrder.ID, Path: null, TradeType: Number(this.chosen[0].TradeID)});
    },
    OptOutCancel()
    {
        if(this.chosen[this.chosen.length - 1].TradeID || this.chosen[this.chosen.length - 1].status !== "Unsubmitted")
            this.appState = 'ChooseExem';
        else
            this.appState = 'FillExem';
    }
  },
}
</script>

<style lang="scss" scoped>

#iframeMain
{
    background-color: white;
    text-align: center;
    overflow: hidden;
    padding: .5em;
    width: 100% !important;
    min-width:max-content;
}

.OrderHeader
{
    text-align: left;
    width: 100%;
}

//rework
.chosen
{
    border: 1px solid #00000033;
    border-radius: 5px;
    width: 95%;
    //margin-left: auto;
    //margin-right: auto;
    margin: 8px auto 8px auto;
    padding: 1px;
    font-size: large;
    text-align: left;
    vertical-align: middle;

    :first-child
    {
        padding-left: 5px;
        width: calc(100% - 75px);
        overflow-wrap: break-word ;
    }
    > :nth-child(2)
    {
        width: fit-content;
        vertical-align: top;
        margin-top: 2px;
        //border: 1px solid;
        border: none;
        background-color: transparent;
        width: 75px;
        border-radius: 8px;
        color: inherit;
        font-size: 80%;
    }

}

.chosen div
{
    display: inline-block;
}

#ChooseTrade > :nth-child(2)
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

#ChooseExem
{
    width: 95%;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

#ChooseExem > :nth-child(2)
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.optionCard
{
    padding: 10px;
    border: 1px solid #00000033;
    border-radius: 5px;
    
    display: inline-block;
    position: relative;

    margin: 5px;
    flex-grow: 1;
    width: 225px;
    //min-width: 225px;
    max-width: 225px;
    height: 225px;

    animation: fadeIn .5s;

    > :first-child
    {
        font-weight: bold;
    }
    > :nth-child(2)
    {
        position: absolute;
        top: 40%;
        width: calc(100% - 20px);
        line-height: 20px;
        overflow-y: hidden;
        text-overflow: ellipsis;
    }
    > :nth-child(3)
    {
        position: absolute;
        right: .5em;
        bottom:0px;
        height: 36px;
        display: flex;
        align-items: center;
    }

    > h2
    {
        position: absolute;
        left: 0;
        right: 0;
        top:calc((100% - 1em) / 2);
        bottom:0;
        margin: auto;
        vertical-align: middle;
        text-transform: uppercase;
        pointer-events: none;
    }
}

.StatusLabel
{
    padding-top: 2px;
    display: inline-block;
    margin-right: 3px;
}

@keyframes fadeIn
{
    0% {opacity: 0;}
    100% {opacity: 1;}
}

#FormCard
{
    width: 95%;
    border: 1px solid #00000033;
    border-radius: 5px;
    //min-height: 300px;
    padding: 1em;

    margin-left: auto;
    margin-right: auto;

    animation: fadeIn .5s;

    position: relative;
}

#BackButton
{
    //width: fit-content;
    //vertical-align: top;
    //border: 1px solid;
    border: none;
    background-color: transparent;
    width: 75px;
    //border-radius: 8px;
    color: inherit;
    //font-size: 80%;

    margin-top:2px;
    position: absolute;
    right: 0;
    top:0;
}

.OptOut
{
    margin: .25em .2em .5em .2em;
    border: 1px solid #666666;
}

.OptOutMessage
{
    height: fit-content;
}

#InputForm, #FormInfo, #FormCard
{
    text-align: left;
}

#FormInfo
{
    width: 100%;
    > :first-child
    {
        width: calc(100% - 50px);
        font-size: 18px;
        font-weight: normal;
    }
    > :nth-child(2)
    {
        margin-bottom:0;
    }
    > :nth-child(3)
    {
        width: fit-content;
        cursor: pointer;
        text-decoration: underline;
        margin-bottom: 0;
    }
    > :nth-child(4)
    {
        margin-bottom: 8px;
    }
    
}

#InputForm
{
    width: fit-content;
    display: inline-block;
}

#OrderInfo
{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    > :nth-child(1)
    {
        vertical-align: top;
        width: 50%;
        display: inline-block;
        min-width: 200px;
    }
    > :nth-child(2)
    {
        vertical-align: top;
        width: 50%;
        display: inline-block;
        min-width: 250px;
    }
}

#IDmeBtn
{
    background:center / contain no-repeat url(https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/verify.svg);
    height: 44px;
    width: 220px;
    border: none;
    padding: 0;
    margin-top: 8px;
    display: block;
}

#IDmeA
{
    color: #777;
    text-decoration: underline;
    cursor: pointer;
    line-height: 12px;
    font-size: 11px;
}

#IDmeA:hover
{
    text-decoration: none;
}

button#formNextButton[disabled] {
    background-color: lightgrey;
    cursor: not-allowed;
}
</style>